@import "src/app/styles/vars.scss";

.container {
  width: 100%;
  position: fixed;
  bottom: 40px;
  left: 0;
}

.content {
  width: 100%;
  padding: 0 $layoutPadding;
  display: flex;
  justify-content: space-between;
}

.text {
  max-width: 668px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;

  color: #FFFFFF;
}

.licenses {
  display: flex;
  align-items: center;
  gap: 20px;
}

.licenceText {
  max-width: 128px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;

  color: #FFFFFF;

}