@import "src/app/styles/vars.scss";

.header {
  position: sticky;
  top: 0;
  background: transparent;
  z-index: $headerZIndex;
  height: $headerHeight;
  padding: 0 $layoutPadding;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $ipadWith) {
    padding: 0 $layoutPaddingIpad;
  }
  @media screen and (max-width: $iphoneWidth) {
    padding: 0 $layoutPaddingMobile;
  }
}

.locale, .burgerIcon, .logo {
  cursor: pointer;
}

.divider, .locale {
  color: #FFFFFF;
}

.highlightedLocale {
  color: $blue
}

.shadowed {
  box-shadow: 0 0 10px rgba(0,0,0,0.4);
}