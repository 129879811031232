//fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
@import "vars";

//reset

*, *:before, *:after{
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-family: Inter;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main{
  display: block;
}

body{
  line-height: 1;
}

ol, ul{
  list-style: none;
}

blockquote, q{
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
  content: '';
  content: none;
}

table{
  border-collapse: collapse;
  border-spacing: 0;
}

input{
  -webkit-appearance: none;
  border-radius: 0;
}

//other global styles

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000 !important;
  text-shadow: none !important;
  font-weight: 600 !important;

}
.ant-tabs .ant-tabs-tab:hover {
  color: #000 !important;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #000 !important;
}

.ant-input:focus, .ant-input:hover, .ant-input:active, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper:hover, .ant-input-affix-wrapper:active {
  border-color: black !important;
  box-shadow: none !important;
}

.ant-btn {
  height: auto !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 12px !important;
  padding: 8px 15px !important;
}

//.ant-btn-default:not(:disabled) {
//  color: white !important;
//  background: $green !important;
//  border-color: $green !important;
//  box-shadow: 0 1px 3px rgba(47, 43, 67, 0.1), inset 0px -1px 0px rgba(47, 43, 67, 0.1) !important;
//}

.ant-input::placeholder {
  color: rgba(47, 43, 67, 0.6);
  font-weight: 500;
  font-size: 16px;
}

.ant-progress-outer {
  margin-inline-end: 0 !important;
  padding-inline-end: 0 !important;
}

.ant-progress-inner {
  border-radius: 4px !important;
  height: 16px !important;
}

.ant-progress-bg {
  height: 16px !important;
}

.ant-progress-text {
  display: none !important;
}

body {
  background-image: url("../../assets/unshared/car.png");
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.app {
  height: 100%;
  position: relative;
}

div#root {
  height: 100%;
}