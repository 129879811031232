@import "src/app/styles/vars";

.btn {
    color: white !important;
    background: $green !important;
    border-color: $green !important;
    box-shadow: 0 1px 3px rgba(47, 43, 67, 0.1), inset 0px -1px 0px rgba(47, 43, 67, 0.1) !important;
}

.withShieldAndArrowBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}