@import "src/app/styles/vars.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: absolute;
  top: 50%;
  transform: translate(0 ,-50%);
  right: $layoutPadding;
  @media screen and (max-width: $ipadWith) {
    right: $layoutPaddingIpad;
  }
  @media screen and (max-width: $iphoneWidth) {
    right: $layoutPaddingMobile;
  }
}

.content {
  width: 624px;
  max-height: 463px;

  background: #FFFFFF;
  border: 1px solid #D3D3D3;
  border-radius: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
}

.contentLogoRow {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.discountImg {
  position: absolute;
  right: -40px;
  top: 40%;
  transform: translate(0 ,-40%);
}

.textRow {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
}

.inputRow {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 14px;
}

.phoneInput {
  width: 100% !important;
  box-shadow: 0px 0px 0px 3px rgba(80, 200, 120, 0.48);
  border-radius: 12px;
  padding: 18px;
}

.infoContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.infoText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #BDBDBD;
}

.images {
  display: flex;
  justify-content: start;
  gap: 20px;
}