$layoutPadding: 120px;
$layoutPaddingIpad: 32px;
$layoutPaddingMobile: 16px;
$headerHeight: 64px;
$modalZIndex: 3;
$pickerZIndex: 4;
$headerZIndex: 5;
$blue: #2F80ED;
$black: #000;
$green: #50C878;
$gray: rgba(47, 43, 67, 0.5);
$darkGray: rgba(47, 43, 67, 0.6);
$ipadWith: 1024px;
$iphoneWidth: 660px;